import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '../../services/auth.services';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  @ViewChild('username') username: ElementRef;

  private elementRef: ElementRef;

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  hide: boolean = true;
  loggedIn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private storage: StorageService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(ElementRef) elementRef: ElementRef
  ) {
    // verify if already logged in
    if (this.storage.getToken()) {
      // change password da login o da pagina profilo
      this.loggedIn = true;
    }

    this.elementRef = elementRef;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],// passwordMatchValidator],
      // }
    }, { validators: [passwordMatchValidator] } as AbstractControlOptions
    );
  }

  ngAfterViewInit() {
    this.username.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  focusFirstInvalid(form: FormGroup) {
    for (const key of Object.keys(form.controls)) {
      // recupera il primo oggetto invalido nella form in input
      if (form.controls[key].invalid) {
        // lo seleziona
        const invalidControl = this.elementRef.nativeElement.querySelector('[formControlName="' + key + '"]');
        invalidControl.focus();
        // esce
        break;
      }
    };
  }

  onSubmit() {
    this.submitted = true;

    // stop se la form è invalida
    if (this.loginForm.invalid) {
      // seleziona il primo elemento invalido della form
      this.focusFirstInvalid(this.loginForm);
      return;
    }

    this.loading = true;
    // console.log(this.f);
    // return;

    this.auth.changePassword(this.f.username.value, this.f.password.value, this.f.newPassword.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.router.navigate(['/profilo']);
        },
        error: error => {
          this.error = error;
          this.loading = false;
        }
      });
  }

  get newPassword() { return this.loginForm.get('newPassword'); }
  get confirmPassword() { return this.loginForm.get('confirmPassword'); }

  onPasswordInput() {
    if (this.loginForm.hasError('passwordMismatch'))
      this.confirmPassword.setErrors([{ 'passwordMismatch': true }]);
    else if (this.loginForm.get('confirmPassword').value != '')
      // else
      this.confirmPassword.setErrors(null);
  }

}

export const passwordMatchValidator: ValidatorFn = (loginForm: FormGroup): ValidationErrors | null => {
  if (loginForm.get('newPassword').value === loginForm.get('confirmPassword').value)
    return null;
  else
    return { passwordMismatch: true };
};