import { Component } from '@angular/core';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  constructor(
    private storage: StorageService,
  ) { }

  contract :string;
  // tablesList: string[] = [];
  // tablesArray: Object[] = [];
  // fieldsList = ['field', 'type', 'length', 'required', 'crud'];
  // fieldsObj = [
  //   { 'name': 'field', 'label': 'Nome colonna' },
  //   { 'name': 'type', 'label': 'Formato' },
  //   { 'name': 'length', 'label': 'Lunghezza max' },
  //   { 'name': 'required', 'label': 'Obbligatorio' },
  //   { 'name': 'crud', 'label': 'CRUD' }
  // ];

  ngOnInit() {
    // const role = this.storage.getRole();
    this.contract = this.storage.getUserContract();
  }
}
