<button mat-button routerLink="..">
    <mat-icon>chevron_left</mat-icon>Indietro
</button>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab label="{{options['detailTitle'] || 'Main'}}">
        <div style="padding: 12px; background-color: white;">
            <button mat-button (click)="openDialog('update', data);" *ngIf="data['editable']==1 || role=='admin'"
                style="position: absolute; right: 0px; z-index:2; margin-right: 12px;">
                <mat-icon style="margin-right: 0px;">edit</mat-icon>
            </button>

            <ng-container *ngFor="let group of fieldsGroups">
                <div class="group-wrapper" *ngIf="getGroupFields(group).length > 0">
                    <label class="group-title">{{group}}</label>
                    <ng-container *ngFor="let field of getGroupFields(group)">
                        <span
                            style="min-width:250px; max-width:100%; display: inline-block; margin-bottom: 12px; vertical-align: top;">
                            <small style="color: darkgrey;">{{visibleFieldsKV[field.name]['label']}}</small>
                            <br />
                            <b>
                                <ng-container [ngSwitch]="visibleFieldsKV[field.name]['format']">
                                    <ng-container *ngSwitchCase="'number'">
                                        {{data[field.name]}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'currency'">
                                        € {{data[field.name]}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'string'">
                                        <a *ngIf="visibleFieldsKV[field.name]['linkText']?.length > 0"
                                            [routerLink]="[visibleFieldsKV[field.name]['linkText'], data[visibleFieldsKV[field.name]['linkItemField']]]">
                                            {{data[field.name]}}
                                        </a>
                                        <span *ngIf="!(visibleFieldsKV[field.name]['linkText']?.length > 0)" class="pre-text">
                                            {{data[field.name]}}
                                        </span>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'datetime'">
                                        {{data[field.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'date'">
                                        {{data[field.name] | date: 'dd/MM/yyyy'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'time'">
                                        {{data[field.name]*1000 | date: 'HH:mm':'UTC'}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'boolean'">
                                        <mat-icon *ngIf="data[field.name]==true || data[field.name]==1">check</mat-icon>
                                        <mat-icon
                                            *ngIf="data[field.name]==false || data[field.name]==0">minimize</mat-icon>
                                    </ng-container>
                                </ng-container>
                            </b>
                        </span>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </mat-tab>

    <mat-tab *ngFor="let link of linked">
        <ng-template mat-tab-label>
            <div matBadge="{{dynamicLinked[link?.sourceName]?.length ?? 0}}" matBadgeOverlap="false"
                class="demo-section">
                {{link?.title}}
            </div>
        </ng-template>

        <!-- Select di aggiunta relazione -->
        <span *ngIf="!link.select && !link.addFields" style="margin: 16px 0 0 16px">
            <i>Per questa entità associata, la modifica è possibile solo direttamente nell'entità associata</i>
        </span>
        <button mat-button *ngIf="( data['editable']==1 || role=='admin' ) && ( link.select || link.addFields )"
            style="margin: 16px 0 0 16px"
            (click)="linkedAddShow[link.sourceName] ? linkedAddShow[link.sourceName] = false : linkedAddShow[link?.sourceName] = true">
            <span *ngIf="!linkedAddShow[link.sourceName]">Mostra form inserimento</span>
            <span *ngIf="linkedAddShow[link.sourceName]">Nascondi form inserimento</span>
        </button>

        <div style="margin:16px" *ngIf="linkedAddShow[link.sourceName]">
            <ng-container *ngIf="link.select">
                <!-- Form di associazione -->
                <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <mat-label>{{link?.title}}</mat-label>
                    <mat-select [(ngModel)]="linkedAddEntries[link.select?.sourceName]" matInput multiple>
                        <ng-container *ngFor="let opt of dynamicData[link.select?.sourceName]['data']">
                            <mat-option [value]="opt[link.select?.id]"
                                *ngIf="!isLinkedUsed(link, opt[link.select?.id])">
                                {{opt[link.select?.label]}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <button mat-button style="margin-left: 8px"
                    *ngIf="linkedAddEntries[link.select?.sourceName]?.length > 0" (click)="addLinked(link)">
                    Aggiungi ({{linkedAddEntries[link.select?.sourceName]?.length}})
                </button>
            </ng-container>
            <ng-container *ngIf="!link.select && link.addFields">
                <!-- Form di inserimento libero -->
                <div *ngFor="let col of link.addFields">
                    <p *ngIf="col.form.type=='textarea'">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                            <mat-label>{{col.label}}</mat-label>
                            <textarea #input matInput type="text" value="" [(ngModel)]="linkedAddForm[col.form.name]"
                                placeholder="{{col.form.placeholder}}"></textarea>
                            <mat-hint *ngIf="col.form.maxLength" align="end">
                                {{input.value.length}}/{{col.form.maxLength}}
                            </mat-hint>
                            <mat-error *ngIf="col.form?.isRequired && linkedAddForm[col.form.name]?.length > 0">
                                Il campo '{{col.label}}' è obbligatorio
                            </mat-error>
                            <mat-error align="end"
                                *ngIf="col.form?.maxLength > 0 && linkedAddForm[col.form.name]?.length < col.form.maxLength">
                                {{input.value.length}}/{{col.form.maxLength}}
                            </mat-error>
                        </mat-form-field>
                    </p>

                    <p *ngIf="col.form.type=='select'">
                        <mat-form-field appearance="outline" subscriptSizing="dynamic">
                            <mat-label>{{col.label}}</mat-label>
                            <mat-select matInput [(ngModel)]="linkedAddForm[col.form.name]">
                                <mat-option *ngFor="let opt of dynamicData[col.form.name]['data']"
                                    [value]="opt[dynamicData[col.form.name].form?.select.id]">
                                    {{opt[dynamicData[col.form.name].form?.select.label]}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="col.form?.isRequired && linkedAddForm[col.form.name]?.length > 0">
                                Il campo '{{col.label}}' è obbligatorio
                            </mat-error>
                        </mat-form-field>
                    </p>
                </div>
                <button mat-button style="margin-left: 8px" (click)="addLinkedForm(link)">
                    Aggiungi
                </button>
            </ng-container>
        </div>

        <span *ngIf="dynamicLinked[link?.sourceName]?.length == 0">
            <br />
            <label style="margin: 16px 0 0 16px">Nessun record da visualizzare...</label>
        </span>
        <div class="mat-elevation-z8" style="margin:16px">
            <mat-table [dataSource]="dynamicLinked[link?.sourceName]"
                *ngIf="dynamicLinked[link?.sourceName]?.length > 0">
                <ng-container *ngFor="let col of getSortedActive(link?.fields)" matColumnDef="{{col.name}}">
                    <mat-header-cell *matHeaderCellDef>{{col.label}}</mat-header-cell>
                    <mat-cell *matCellDef="let cell" [ngClass]="{
                        'numberTd'   : col?.format=='number',
                        'stringTd'   : col?.format=='string',
                        'datetimeTd' : col?.format=='datetime',
                        'dateTd'     : col?.format=='date',
                        'booleanTd'  : col?.format=='boolean'
                        }">
                        <small class="d-inline-mobile" style="color: darkgrey">{{col.label}}:&nbsp;</small>
                        <ng-container [ngSwitch]="col?.format">
                            <ng-container *ngSwitchCase="'number'">
                                {{cell[col.name]}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'string'">
                                {{cell[col.name]}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'datetime'">
                                {{cell[col.name] | date: 'dd/MM/yyyy HH:mm:ss'}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'date'">
                                {{cell[col.name] | date: 'dd/MM/yyyy'}}
                            </ng-container>
                            <ng-container *ngSwitchCase="'boolean'">
                                <mat-icon *ngIf="cell[col.name]==true || cell[col.name]==1">check</mat-icon>
                                <mat-icon *ngIf="cell[col.name]==false || cell[col.name]==0">minimize</mat-icon>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                {{cell[col.name]}}
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <!-- Aciton Column -->
                <ng-container matColumnDef="actionColumn" stickyEnd>
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let cell" class="table-action-button">
                        <button mat-icon-button aria-label="Action button" [matMenuTriggerFor]="actionOptionsMenu"
                            [matMenuTriggerData]="{routerLink: link?.routerLink, id: cell['id']}"
                            (click)="handleActionsClick($event)" *ngIf="data['editable']==1 || role=='admin'">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <!-- -->

                <mat-header-row
                    *matHeaderRowDef="getPropertyList(getSortedActive(link?.fields), 'name').concat('actionColumn')"></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: getPropertyList(getSortedActive(link?.fields), 'name').concat('actionColumn')"></mat-row>
            </mat-table>

            <mat-menu #actionOptionsMenu="matMenu" xPosition="before">
                <ng-template matMenuContent let-id="id" let-routerLink="routerLink">
                    <button mat-menu-item routerLink="/{{routerLink}}/{{id}}">
                        <mat-icon>open_in_new</mat-icon>
                        <span>Apri</span>
                    </button>
                    <button mat-menu-item (click)="deleteLink(link?.sourceName, id)">
                        <mat-icon>delete</mat-icon>
                        <span>Elimina</span>
                    </button>
                </ng-template>
            </mat-menu>
        </div>
    </mat-tab>
</mat-tab-group>