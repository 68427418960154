import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, catchError, switchMap, throwError } from 'rxjs';

import { StorageService } from './storage.service';
import { AuthService } from './auth.services';
import { NotifierService } from './notifier.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private storage: StorageService,
    private auth: AuthService,
    private notifier: NotifierService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isRefreshToken = request.url.toString().indexOf('/api/refresh_token') > -1;
    // Aggiungi il token di autenticazione alle richieste
    const token = isRefreshToken ? this.storage.getRefreshToken() : this.storage.getToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    // return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (isRefreshToken) {
          // l'errore è causato dal metodo refresh_token
          this.auth.logout();
          return throwError('refreshToken scaduto');
        }

        if (error.status === 401) {
          // Il token è scaduto, esegue il refresh token
          // Prima verifica se c'è un refreshToken
          console.log('token scaduto');
          const refreshToken = this.storage.getRefreshToken();
          if (!refreshToken) {
            this.auth.logout();
            return EMPTY;
          }
          return this.auth.refreshToken().pipe(
            switchMap((response) => {
              console.log('RefreshToken');
              // Aggiorna il token di accesso nel servizio di autenticazione
              this.storage.saveUser(response);
              const newToken = this.storage.getToken();
              // Riprova la richiesta originale con il nuovo token
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${newToken}`
                }
              });
              return next.handle(request);
            })
          );
        }

        if (error.status === 425) {
          // Accesso con password temporanea
          this.auth.expiredPassword();
        }

        if (error.status === 444) {
          // Caso di eccezione gestita dal back-end, viene mostrato a video
          // this.notifier.showError('Errore', error.error['detail']);
          return next.handle(request);
        }

        // Altri errori, restituisci l'errore così com'è
        return throwError(error);
      })
    );
  }
}
