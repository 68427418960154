<div class="home_title">
    <p>Presenze e Assenze</p>
</div>
<div class="flex-row">
    <div class="home_ripple flex-column mat-elevation-z4" matRipple [routerLink]="'/presenze'">
        <span class="home_icon"><mat-icon>handshake</mat-icon></span>
        <span class="home_title">Presenze</span>
    </div>
    <div class="home_ripple flex-column mat-elevation-z4" matRipple [routerLink]="'/assenze'"
        *ngIf="['Partinta Iva', 'Occasionale'].indexOf(contract) == -1">
        <span class="home_icon"><mat-icon>work_off</mat-icon></span>
        <span class="home_title">Assenze</span>
    </div>
</div>
<div class="home_title">
    <p>Aiuto e Statistiche</p>
</div>
<div class="flex-row">
    <div class="home_ripple flex-column mat-elevation-z4" matRipple [routerLink]="'/segnalazioni'">
        <span class="home_icon"><mat-icon>playlist_add_check</mat-icon></span>
        <span class="home_title">Segnalazioni</span>
    </div>
    <div class="home_ripple flex-column mat-elevation-z4" matRipple [routerLink]="'/statistiche'">
        <span class="home_icon"><mat-icon>leaderboard</mat-icon></span>
        <span class="home_title">Statistiche</span>
    </div>
</div>