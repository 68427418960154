<mat-vertical-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">
    <!-- Step 1 - selezione dell'entità -->
    <mat-step [stepControl]="entitySelectFormGroup">
        <ng-template matStepLabel>
            Selezione entità
            <span *ngIf="selectedEntityLabel!=''"> - <b>{{selectedEntityLabel}}</b></span>
        </ng-template>
        <ng-template matStepContent>
            <p>Selezionare l'entità di cui si vuole procedere con un upload massivo.</p>
            <form [formGroup]="entitySelectFormGroup">
                <mat-form-field appearance="outline">
                    <mat-label>Seleziona entità</mat-label>
                    <mat-select matInput formControlName="entityType">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let entity of entitySelect" value="{{entity.value}}">
                            {{entity.label}}
                        </mat-option>
                    </mat-select>
                    <mat-error>La selezione è obbligatoria</mat-error>
                </mat-form-field>
                <div>
                    <button mat-button matStepperNext color="primary">Avanti</button>
                </div>
            </form>
        </ng-template>
    </mat-step>
    <!-- Step 2 - caricamento del file -->
    <mat-step [stepControl]="fileSelectFormGroup">
        <ng-template matStepLabel>
            Selezione file
            <span *ngIf="selectedFileName!=''"> - <b>{{selectedFileName}}</b></span>
        </ng-template>
        <ng-template matStepContent>
            <form [formGroup]="fileSelectFormGroup" (submit)="fileSelectSubmit()">
                <p>È possibile scaricare un <button mat-button type="button"
                        (click)="downloadTemplate()">template</button>
                    da compilare.</p>
                <p>Selezionare il file contenente i dati da caricare.</p>
                <p>NB: le date devono essere espresse nel formato "aaaa-mm-gg" (es: 1985-10-25)</p>
                <p>
                    <input hidden type="file" formControlName="uploadInput" #uploadInputHtml
                        (change)="loadFile($event)">
                    <button mat-button type="button" (click)="uploadInputHtml.click()">File</button>
                    <span [ngClass]="{'colorRed' : fileSelectAlert}">
                        {{fileName || 'Nessun file selezionato'}}
                    </span>
                </p>
                <p>Indicare il numero di righe del file da ignorare, prima dell'intestazione.</p>
                <p>
                    <mat-form-field appearance="outline">
                        <mat-label>Righe da ignorare</mat-label>
                        <input matInput type="number" step="1" min="0" formControlName="skipRows" (change)="readFile()">
                        <mat-error>La selezione è obbligatoria</mat-error>
                    </mat-form-field>
                </p>
                <div>
                    <button mat-button matStepperPrevious>Indietro</button>
                    <button mat-button matStepperNext color="primary">Avanti</button>
                </div>
            </form>
        </ng-template>
    </mat-step>
    <!-- Step 3 - definizione dei campi -->
    <mat-step [completed]="allUpsertSelected()">
        <ng-template matStepLabel>
            Definizione campi
            <span *ngIf="definedFields!=''"> - <b>{{definedFields}}</b></span>
        </ng-template>
        <ng-template matStepContent>
            <p>Definire per ciascun campo, recuperati dall'intestazione del file selezione, quale sia il corrispondente
                campo dell'entità selezionata.</p>
            <p>I campi indicati come chiave di ricerca sono necessari per verificare che l'azione di import generi un
                inserimento o un aggiornamento. Non è possibile quindi procedere con il processamento senza aver
                definito tutti questi campi.</p>
            <p>I campi indicati come obbligatori sono necessari nel caso di nuovi inserimenti. Nel caso in cui non tutti
                i campi obbligatori siano definiti, verranno processate solo le occorrenze che corrispondo ad
                aggiornamento, ovvero dove le chiavi di ricerca trovano corrispondenza nella base dati.</p>
            <br />
            <p>Chiavi di ricerca **:
                <span *ngFor="let field of selectedEntityUpsertFields; last as last">
                    <span [ngClass]="isFieldSelected(field) ? 'colorGreen' : 'colorRed'">{{field.label}}</span>
                    <span *ngIf="!last">, </span>
                </span>
            </p>
            <p>Campi obbligatori *:
                <span *ngFor="let field of selectedEntityUpdateRequiredFields; last as last">
                    <span [ngClass]="isFieldSelected(field) ? 'colorGreen' : ''">{{field.label}}</span>
                    <span *ngIf="!last">, </span>
                </span>
            </p><br />

            <form [formGroup]="fieldsDefinition">
                <ul>
                    <li *ngFor="let fileHeader of fileHeaderList; index as i">
                        <mat-form-field appearance="outline">
                            <mat-select matInput [(value)]="fieldValues[i]">
                                <mat-option value="_ignore" selected="selected">- Ignora campo -</mat-option>
                                <mat-option *ngFor="let field of selectedEntityUpdateFields" value="{{field.name}}">
                                    {{field.label}}
                                    <span *ngIf="selectedEntityUpdateRequiredFields.indexOf(field)!=-1"> *</span>
                                    <span *ngIf="selectedEntityUpsertFields.indexOf(field)!=-1">*</span>
                                </mat-option>
                            </mat-select>
                            <mat-error>La selezione è obbligatoria</mat-error>
                        </mat-form-field>
                        {{fileHeader}}
                    </li>
                </ul>
                <div>
                    <button mat-button matStepperPrevious>Indietro</button>
                    <button mat-button matStepperNext color="primary">Avanti</button>
                </div>
            </form>
        </ng-template>
    </mat-step>
    <!-- Step 4 - riepilogo pre processamento e avvio processamento -->
    <mat-step>
        <ng-template matStepLabel>
            Upload
            <span *ngIf="validRowsCount!=''"> - <b>{{validRowsCount}}</b></span>
        </ng-template>
        <ng-template matStepContent>
            <p *ngIf="invalidRows.length > 0">ATTENZIONE: Alcune occorrenze del file non rispettano i criteri di
                obbligatorietà dei campi definiti o i formati previsti.
                Di seguito i numeri delle riga delle occorrenze in questione:
                <b>{{invalidRows | json}}</b>.
            </p>
            <br />
            <p>Sulla base dei campi definiti, per questo caricamento verranno eseguite operazioni di <u>aggiornamento di
                    occorrenze esistenti</u><span *ngIf="allRequiredSelected()"> e <u>inserimento di nuove
                        occorrenze</u></span>. </p>
            <p>Al termine del caricamento verrà visualizzato un riepilogo.</p>
            <div>
                <button mat-button (click)="stepper.reset()">Indietro</button>
                <button mat-raised-button color="primary" matStepperNext (click)="upsert()">Procedi</button>
            </div>
        </ng-template>
    </mat-step>
    <!-- Step 5 - riepilogo post processamento -->
    <mat-step>
        <ng-template matStepLabel>Riepilogo caricamento</ng-template>
        <ng-template matStepContent>
            <p>Avanzamento: {{processedRows}}/{{rowsToProcess}}</p>
            <ul>
                <ng-container *ngFor="let row of uploadResult; index as i; first as first">
                    <li *ngIf="!first && row != undefined">
                        Riga {{i}}:&nbsp;
                        <span *ngIf="row['action'] == 'insert'">Inserimento effettuato con successo</span>
                        <span *ngIf="row['action'] == 'update'">Aggiornamento effettuato con successo</span>
                        <span *ngIf="row['action'] != 'insert' && row['action'] != 'update'">{{row}}</span>
                    </li>
                </ng-container>
            </ul>
        </ng-template>
    </mat-step>
</mat-vertical-stepper>