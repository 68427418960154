import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from './guards/role-guard.guard';

import { EntityDetailComponent } from './components/entity-detail/entity-detail.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HomeComponent } from './components/home/home.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UploadComponent } from './components/upload/upload.component';
import { ReportComponent } from './components/report/report.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { StatsComponent } from './components/stats/stats.component';

const routes: Routes = [
  {
    path: 'allievi/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'students' },
  },
  {
    path: 'allievi',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'students' },
  },
  {
    path: 'anni',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'years' },
  },
  {
    path: 'assenze',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user'], source: 'absences' },
  },
  {
    path: 'presenze/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user'], source: 'assistances' },
  },
  {
    path: 'presenze',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user','principal'], source: 'assistances' },
  },
  {
    path: 'attivita/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'activities' },
  },
  {
    path: 'attivita',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'activities' },
  },
  {
    path: 'operatori/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'users' },
  },
  {
    path: 'operatori',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'users' },
  },
  {
    path: 'reti/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'networks' },
  },
  {
    path: 'reti',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'networks' },
  },
  {
    path: 'istituti/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'schools' },
  },
  {
    path: 'istituti',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'schools' },
  },
  {
    path: 'segnalazioni/:id',
    component: EntityDetailComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user'], source: 'requests' },
  },
  {
    path: 'segnalazioni',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user'], source: 'requests' },
  },
  {
    path: 'upload',
    component: UploadComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'] },
  },
  {
    path: 'statistiche',
    component: StatsComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user'] },
  },
  {
    path: 'report/istituto-mese',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'school_month' },
  },
  {
    path: 'report/operatore-mese',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'user_month' },
  },
  {
    path: 'report/istituto-settimana',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'school_week' },
  },
  {
    path: 'report/operatore-settimana',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'user_week' },
  },
  {
    path: 'report/contratto-settimana',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'contract_week' },
  },
  {
    path: 'report/sostituzione-settimana',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'substitution_week' },
  },
  {
    path: 'report/settimane-incomplete',
    component: ReportComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'user_missing_weeks' },
  },
  {
    path: 'archivio/presenze',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'assistances_full' },
  },
  {
    path: 'archivio/assenze',
    component: EntityListComponent,
    canActivate: [RoleGuard],
    data: { roles: ['admin'], source: 'absences_full' },
  },
  { path: 'login', component: LoginComponent },
  { path: 'cambio-password', component: ChangePasswordComponent },
  {
    path: 'profilo',
    component: ProfileComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user','principal'] },
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'termini-e-condizioni',
    component: TermsAndConditionsComponent
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [RoleGuard],
    data: { roles: ['user'] },
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
