import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Report, reportOption } from 'src/app/models/report.model';
import { ApiService } from 'src/app/services/api.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { Subscription } from 'rxjs';
import { FieldsModel, FiltersModel } from 'src/app/models/entity.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// formato data visualizzato nel filtro laterale
export const MY_FORMATS = {
  parse: {
    // dateInput: 'LL',
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
  useUtc: true
};

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ReportComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private notifier: NotifierService,
    private formBuilder: FormBuilder,
    private matPaginatorIntl: MatPaginatorIntl
  ) { }

  routeData = this.route.data['_value'];
  sourceName: string;
  page: Report;
  fields: FieldsModel[];
  fieldsList: string[];
  formFields: FieldsModel[];
  formSelectList: string[];
  options: reportOption;
  dynamicData = [];
  filterForm: FormGroup = this.formBuilder.group({});
  formIsChanged: boolean = false;
  formChangeSubscription: Subscription;
  loading: boolean = false;
  showData: boolean = false;
  report: any = [];
  data: typeof this.page.model[] = [];
  dataSource = new MatTableDataSource<typeof this.data>(null);

  visibleFields: FieldsModel[];
  visibleFieldsKV: FieldsModel[] = [];
  visibleFieldsList: string[] = [];

  weekFilter = (d: Date | null): boolean => {
    const day = moment(d || new Date()).weekday();
    // solo i lunedì
    return day == 1;
  };

  ngOnInit() {
    // traduzione dell'oggetto paginator
    this.matPaginatorIntl.itemsPerPageLabel = 'Elementi per pagina';
    this.matPaginatorIntl.nextPageLabel = 'Pagina successiva';
    this.matPaginatorIntl.previousPageLabel = 'Pagina precedente';
    this.matPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize === 0) {
        return 'Nessun elemento';
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} – ${endIndex} di ${length}`;
    };

    // parametro source ricevuto in input da app-routing.module.ts
    this.sourceName = this.routeData['source'];
    this.page = new Report(this.sourceName);
    // tutti i campi (oggetto Field)
    this.fields = this.page.getFields();
    // tutti i campi (lista nomi)
    this.fieldsList = this.page.getFieldsList();
    // tutti i campi che hanno form (oggetto Field)
    this.formFields = this.page.getFormFields();
    // tutti i campi che hanno select (lista nomi)
    this.formSelectList = this.page.getFormSelectList();
    // oggetto option
    this.options = this.page.getOptions();

    this.visibleFields = this.page.getVisibleFields();
    this.visibleFieldsKV = this.page.getVisibleFieldsKV();
    this.visibleFieldsList = this.page.getVisibleFieldsList();

    // Recupera la lista dei campi che hanno una form select
    this.formSelectList.forEach(fieldName => {
      this.dynamicData[fieldName] = {
        "form": this.page.getFormSelectByFieldName(fieldName),
        "data": []
      };
    });

    // Aggiunge i campi alla form (sia inserimento che modifica)
    this.formFields.forEach(field => {
      if (field.form != null) {
        this.formAddControl(
          field.crud,
          field.name,
          field.form.defaultValue,
          field.form.isRequired,
          field.form.maxLength,
          field.form.pattern
        );
      }
    });
    this.formChangeSubscriber(this.filterForm);

    // carica i dati
    this.loadData();
  }

  formAddControl(crud: FieldsModel["crud"], name: string, value: string | number, isRequired: boolean, maxLength: number, pattern: string) {
    // Aggiunge il campo alla form (sia inserimento che modifica)
    let validatorsList = [];
    if (isRequired) validatorsList.push(Validators.required);
    if (maxLength) validatorsList.push(Validators.maxLength(maxLength));
    if (pattern) validatorsList.push(Validators.pattern(pattern));
    this.filterForm.addControl(name, this.formBuilder.control(value, validatorsList));
  }

  formChangeSubscriber(form: FormGroup) {
    // aggiunge un listener sulla modifica della form
    // viene eseguito solo una volta, per evitare di creare
    // un nuovo subscribe ogni volta che viene invocato il metodo

    // cancella l'eventuale precedente subscription
    if (this.formChangeSubscription) this.formChangeSubscription.unsubscribe();
    // crea una subscription in ascolto del cambiamento dei valori della form
    this.formChangeSubscription = form.valueChanges.subscribe(value => {
      this.formIsChanged = this.formChanged(form);
    });
  }

  formChanged(form: FormGroup): boolean {
    // verifica se la form in input ha valore dirty (cambiati)
    let output = false;
    Object.keys(form.controls).forEach(f => {
      if (form.controls[f].dirty)
        output = true;
    });
    return output;
  }

  loadData() {
    // scarica eventuali dati per le form select
    this.formSelectList.forEach(fieldName => {
      this.api.select(
        typeof {},
        this.dynamicData[fieldName].form.select.sourceName,
        [this.dynamicData[fieldName].form.select.id, this.dynamicData[fieldName].form.select.label],
        this.dynamicData[fieldName].form.select.filters, [], {}).subscribe(
          data => {
            this.dynamicData[fieldName]['data'] = data;
          }
        );
    });
  }

  get f() {
    return this.filterForm.controls;
  }

  getReport(type: ('export' | 'view')) {
    // ferma l'esecuzione se la form non è valida
    if (this.filterForm.invalid) {
      this.notifier.showWarning('Attenzione', 'Form non valida');
      return;
    }

    this.loading = true;
    this.showData = false;

    const filters: FiltersModel[] = this.page.filters.slice();
    const form = this.filterForm;
    this.formFields.forEach(field => {
      if (field.form != null
        // verifica che il campo abbia un valore
        && form.value[field.form.name] != null
        // solo i valori realmente cambiati
        && form.controls[field.form.name].dirty) {
        filters.push({
          'field': field.form.name,
          'operator': '=',
          'value': form.value[field.form.name]
        });
      }
    });

    // scarica i dati dell'entità dall'api
    this.api.select(typeof this.page.model, this.sourceName, this.fieldsList, filters, [], {}).subscribe({
      next: (data) => {
        // download del report
        if (type == 'export')
          this.export(data, 'xlsx');
        // report a video (tabella)
        else if (type == 'view')
          this.view(data);

        this.loading = false;
        return true;
      },
      error: (err) => {
        this.loading = false;
        this.notifier.showError('Errore', 'Si è verificato un errore: ' + err.toString());
        return false;
      }
    });
    return;
  }

  view(data: any) {
    this.data = data;
    this.dataSource = new MatTableDataSource<typeof this.data>(this.data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.showData = true;
  }

  export(data: any, format: ('xlsx' | 'pdf' | 'json' | 'xml' | 'csv')) {
    // se non viene fornito nulla al metodo, restituisce false
    if (data.length > 0) {
      // Recupera l'oggetto JSON esportabile
      // Calcola e aggiunge la riga dei totali
      data = this.addTotals(data);
      // formatta i campi secondo il tipo del modello
      data = this.formatFields(data);
    }

    switch (format) {
      case 'xlsx':
        // Restituisce la lunghezza per ciascuna colonna
        const fitToColumn = data => {
          const columnWidths = [];
          // Larghezza massima della colonna
          const maxWidth = 30;
          for (const property in data[0]) {
            // Larghezza reale della colonna (potenzialmente maggiore della maxWidth)
            const realWidth = Math.max(
              property ? property.toString().length : 0, ...data.map(
                obj => obj[property] ? obj[property].toString().length : 0)
            );
            columnWidths.push({
              // utilizza la realWidth se minore della maxWidth
              wch: Math.min(maxWidth, realWidth)
            });
          }
          return columnWidths;
        };
        // Crea WorkBook e WorkSheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        // rinomina l'intestazione per dare i nomi italiani ai campi
        const new_ws = this.changeHeaders(ws);
        ws['!cols'] = fitToColumn(data);
        // Crea il file
        const sheetName = this.options.filename || 'export';
        const fileName = sheetName + '_' + moment(new Date()).format('YYYYMMDDHHmmss') + '.xlsx';
        XLSX.utils.book_append_sheet(wb, new_ws, sheetName);
        // Esporta il file all'utente
        XLSX.writeFile(wb, fileName);
        break;

      default:
        alert('Non disponibile');
        break;
    }
    return true;
  }

  changeHeaders(ws: XLSX.WorkSheet): XLSX.WorkSheet {
    var range = XLSX.utils.decode_range(ws['!ref']);
    for (var C = range.s.c; C <= range.e.c; ++C) {
      var address = XLSX.utils.encode_col(C) + "1";
      if (!ws[address]) continue;
      const fieldName = ws[address].v;
      const fieldLabel = this.fields.filter(f => f.name == fieldName).map(f => f.label)[0];
      ws[address].v = fieldLabel;
    }
    return ws;
  }

  formatDuration(seconds: number) {
    // trasforma la durata in secondi nel formato HH:mm:ss
    const duration = moment.duration(seconds, 'seconds');
    const duration_hours = Math.floor(duration.asHours());
    const duration_minutes = Math.floor(duration.asMinutes() - duration_hours * 60);
    const duration_seconds = seconds - (duration_hours * 3600) - (duration_minutes * 60);
    return duration_hours + ':' + ('0' + duration_minutes).slice(-2) + ':' + ('0' + duration_seconds).slice(-2);
  }

  formatFields(obj) {
    let newObj = [];
    obj.forEach(row => {
      let newRow = row;
      Object.keys(row).forEach(fieldName => {
        const format = this.fields.filter(f => f.name == fieldName).map(f => f.format)[0];
        switch (format) {
          case 'duration':
            newRow[fieldName] = this.formatDuration(row[fieldName]);
            break;
        }
      });
      newObj.push(newRow);
    });
    return newObj;
  }

  addTotals(obj) {
    let formats = [];
    let totals = [];
    // prepara la lista dei campi che prevedono un totale (in base al formato)
    Object.keys(obj[0]).forEach(fieldName => {
      if (this.fields.filter(f => f.name == fieldName && f.format == 'duration')[0]) {
        formats.push(fieldName);
        totals[fieldName] = 0;
      }
    });
    // cicla le righe e aggiunge il valore dei campi identificati al punto precedente
    obj.forEach(row => {
      formats.forEach(f => {
        totals[f] += row[f];
      });
    });
    // aggiunge la riga totale in fondo all'oggetto da esportare
    obj.push(totals);
    return obj;
  }

  updateFilters(e: any) {
    // applicare i filtri solo alle righe che soddisfano il criterio
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      let matchAny = false;
      // recupero il valore del campo di ricerca libera
      const searchPattern = e.target?.value;
      if (searchPattern) {
        // recupero l'elenco di tutti i campi visibili, escludendo la colonna dell'action
        this.visibleFieldsList.filter(f => { return f != 'actionColumn'; }).forEach(fieldName => {
          const fieldFormat = this.visibleFieldsKV[fieldName].format;
          const fieldValue = data[fieldName];
          // ricerco il valore inserito in uno qualunque dei campi, escludendo booleani e date
          if (fieldFormat != 'boolean' && fieldFormat != 'date' && fieldFormat != 'datetime'
            && fieldValue?.toString().toLowerCase().includes(searchPattern.toLowerCase())) {
            // se trovo anche una solo corrispondenza, non salto la riga in questione
            matchAny = true;
          } else if ((fieldFormat == 'datetime' || fieldFormat == 'date')
            && moment(new Date(fieldValue)).format('DD/MM/YYYY HH:mm:ss').toString()
              .includes(searchPattern.toLowerCase())) {
            // eccezione per le date, dove viene applicato il formato italiano
            matchAny = true;
          } else if (fieldFormat == 'duration' && this.formatDuration(fieldValue).includes(searchPattern.toLowerCase())) {
            // eccezione per le durate, dove viene applicato il formato HH:mm:ss
            matchAny = true;
          }
        });
      } else {
        // non è inserito un valore nella ricerca libera, quindi skippo il controllo
        matchAny = true;
      }

      // se sia la ricerca libera che per singoli campi hanno esito positivo, mostra la riga
      return matchAny;
    };
    // passando un filtro qualunque alla tabella, invoca il filterPredicate custom di cui sopra
    this.dataSource.filter = 'filtroDummy';
  }
}