import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, map } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { FiltersModel } from '../models/entity.model';
import { environment } from '../../environment/environment';

// server test
// const API_URL = 'HTTPS://gass.3cengineering.it:8000/api';
// server prod
//const API_URL = 'https://myitinera.coopitinera.it:8000/api';

// local con proxy
// const API_URL = '/api';

// url contenuta nel file environment
const API_URL = environment.API_URL;

const httpOptions = {
  headers: new HttpHeaders({
    'accept': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private router: Router, private http: HttpClient, private storage: StorageService) { }

  getTables() {
    return this.http.get<any>(API_URL + '/tables/');
  }

  getTable(table: string) {
    const data = {
      "table": table
    };
    return this.http.post<any>(API_URL + '/table/', data, httpOptions);
  }

  testTable() {
    const tables = this.getTables().subscribe(
      val => {
        console.log(val);
        console.log(val[0]);
        const table = this.getTable(val[0]).subscribe(
          t => {
            console.log(t);
          }
        );
      }
    );
  }

  select(dataModel: any, table: string, fields: string[], filters: FiltersModel[], sort: any[], options: {}) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters,
      "sort": sort,
      "options": options ? options : {}
    };

    return this.http.post<typeof dataModel>(API_URL + '/select/', data, httpOptions);
  }

  insert(dataModel: any, table: string, fields: {}) {
    const data = {
      "table": table,
      "fields": fields
    };

    return this.http.post<typeof dataModel>(API_URL + '/insert/', data, httpOptions);
  }

  update(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/update/', data, httpOptions);
  }

  upsert(dataModel: any, table: string, fields: {}, filters: FiltersModel[]) {
    const data = {
      "table": table,
      "fields": fields,
      "filters": filters
    };

    return this.http.post<typeof dataModel>(API_URL + '/upsert/', data, httpOptions);
  }
}
