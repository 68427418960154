import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveUser(data: any) {
    sessionStorage.setItem('token', data.access_token);
    if (data.refresh_token)
      sessionStorage.setItem('refreshToken', data.refresh_token);
    return true;
  }

  getToken() {
    return sessionStorage.getItem('token');
  }

  getMobile() {
    return sessionStorage.getItem('mobile');
  }

  getRefreshToken() {
    return sessionStorage.getItem('refreshToken');
  }

  getRole() {
    return sessionStorage.getItem('role');
  }

  setRole(role: string) {
    sessionStorage.setItem('role', role);
  }

  setUser(user: UserModel) {
    sessionStorage.setItem('role', user.admin ? 'admin' : user.principal ? 'principal' : 'user');
    sessionStorage.setItem('username', user.username);
    sessionStorage.setItem('terms_and_conditions', user.terms_and_conditions.toString());
    sessionStorage.setItem('contract', user.contract);
  }

  getUserUsername(): string {
    return sessionStorage.getItem('username');
  }

  getUserTermsAndConditions(): boolean {
    return sessionStorage.getItem('terms_and_conditions') == '1' ? true : false;
  }

  getUserContract(): string {
    return sessionStorage.getItem('contract');
  }

  deleteUser() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('terms_and_conditions');
    return true;
  }
}
