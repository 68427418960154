<mat-card style="max-height: 100%; overflow-y: auto; min-width: 360px;">
    <mat-card-title-group>
        <mat-card-title class="login_image">
            <img src="../../assets/img/logo-itinera.png" />
        </mat-card-title>
        <mat-card-subtitle>
            <div class="login_title">MY ITINERA</div>
        </mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field appearance="outline" class="fill-width">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" formControlName="username" #username autocomplete="username" />
                    <mat-icon matSuffix *ngIf="f.username.errors==null" class="valid-icon">check</mat-icon>
                    <mat-error *ngIf="f.username.hasError('required')">Username obbligatoria</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" class="fill-width">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"  autocomplete="password" />
                    <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-icon matSuffix *ngIf="f.password.errors==null" class="valid-icon">check</mat-icon>
                    <mat-error *ngIf="f.password.hasError('required')">Password obbligatoria</mat-error>
                </mat-form-field>
            </p>
            <p style="margin-bottom:32px">
                <em>Proseguendo confermi di aver letto e accettato<br /><a href="https://www.coopitinera.it/termini-condizioni-e-privacy-policy-app-myitinera/" target="_blank">Termini, Condizioni e Privacy Policy</a>.</em>
            </p>
            <p *ngIf="error" class="error">
                {{error}}
            </p>
            <div class="button">
                <button [disabled]="loading" type="submit" mat-button color="primary">Login</button>
            </div>
        </form>
    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
</mat-card>