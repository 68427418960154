<mat-sidenav-container class="menu-container" fullscreen>
    <mat-sidenav #sidenav mode="push" [autoFocus]="false" [opened]="opened" (opened)="events.push('open!')"
        (closed)="events.push('close!')">
        <mat-nav-list role="navigation" *ngIf="loggedIn && termsAndConditions">
            <ng-container *ngFor="let item of menu; let index = index">
                <ng-container
                    *ngIf="item.type=='dropdown' && (item.role=='user' || admin) && !(mobile && (item.title == 'Report' || item.title == 'Upload')) && (role != 'principal')">
                    <cdk-accordion>
                        <cdk-accordion-item #accordionItem="cdkAccordionItem" role="button"
                            [attr.aria-expanded]="accordionItem.expanded"
                            [attr.aria-controls]="'accordion-body-' + index">
                            <div (click)="accordionItem.toggle()">
                                <mat-list-item>
                                    <div matListItemTitle>
                                        {{item.title}}
                                        <span class="dropdown-item-toggle">
                                            <mat-icon>{{accordionItem.expanded ? 'keyboard_arrow_down' :
                                                'keyboard_arrow_right'}}</mat-icon>
                                        </span>
                                    </div>
                                </mat-list-item>
                            </div>
                            <div [style.display]="accordionItem.expanded ? '' : 'none'"
                                [attr.id]="'accordion-body-' + index">
                                <ng-container *ngFor="let subitem of item.items">
                                    <mat-list-item *ngIf="subitem.role=='user' || admin" routerLinkActive="active-link"
                                        [routerLink]="[subitem.routerLink]" [routerLinkActiveOptions]="{ exact: true }"
                                        class="sub-list-item">
                                        <mat-icon matListItemIcon>{{subitem.icon}}</mat-icon>
                                        <div matListItemTitle>{{subitem.title}}</div>
                                    </mat-list-item>
                                </ng-container>
                            </div>
                        </cdk-accordion-item>
                    </cdk-accordion>
                </ng-container>
                <ng-container
                    *ngIf="item.type=='item' && (item.role=='user' || admin) && !(mobile && (item.title == 'Report' || item.title == 'Upload')) && (role != 'principal' || item.title == 'Presenze')">
                    <mat-list-item routerLinkActive="active-link" [routerLink]="[item.routerLink]"
                        [routerLinkActiveOptions]="{ exact: true }">
                        <mat-icon matListItemIcon>{{item.icon}}</mat-icon>
                        <div matListItemTitle>{{item.title}}</div>
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar *ngIf="loggedIn">
            <button mat-icon-button aria-label="Menu toggle" (click)="sidenav.toggle()" *ngIf="termsAndConditions">
                <mat-icon>menu</mat-icon>
            </button>
            <span [routerLink]="['/']" style="cursor:pointer;">myItinera</span>
            <span class="mat-toolbar-spacer"></span>
            <button mat-button [routerLink]="['/profilo']">{{username}}</button>
            <button mat-icon-button class="favorite-icon" [routerLink]="['/logout']">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>