import { Component } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  user: UserModel = new UserModel();

  constructor(
    private auth: AuthService
  ) {
    this.auth.checkSession().subscribe(
      data => {
        this.user = data;
      });
  }

}
