<div class="chart-container">
    <h2>Presenze mensili (ore)</h2>
    <mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="datasets.length > 1">
        <mat-label>Operatori</mat-label>
        <!-- <mat-select matInput> -->
        <mat-select matInput [(ngModel)]="selectedDatasets" (selectionChange)="datasetSelection()" multiple>
            <mat-form-field appearance="outline" style="margin: 0px 8px; width: calc(100% - 16px);">
                <!-- <input matInput (keyup)="selectFilter($event)" placeholder="Filtro"> -->
                <input #filter matInput placeholder="Filtro">
            </mat-form-field>
            <mat-option *ngFor="let d of filteredDatasets" [value]="d.id"
                [class.hide]="filter.value !== '' && d.label.toLowerCase().indexOf(filter.value.toLowerCase()) === -1">
                {{d.label}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="chart-canvas">
        <canvas id="MyChart">{{ chart }}</canvas>
    </div>
</div>